import React from 'react';
import { MDBCol, MDBInput, MDBRow } from 'mdbreact';
import FilePreviewElement from '../FilePreviewElement';

class UploadDocumentsToWorkOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: [],
    };
  }

  componentWillUnmount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getUploadedDocuments([]);
  }

  handleFileUpload = (event) => {
    const file = event.target.files;
    if (file.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(file[0]);
      const imageNameSplittedArray = file[0].name.split('.');
      const fileType = (
        imageNameSplittedArray[imageNameSplittedArray.length - 1] === 'png'
        || imageNameSplittedArray[imageNameSplittedArray.length - 1] === 'jpg'
        || imageNameSplittedArray[imageNameSplittedArray.length - 1] === 'jpeg'
        || imageNameSplittedArray[imageNameSplittedArray.length - 1] === 'bmp'
      ) ? 'image' : 'file';
      reader.onloadend = () => {
        this.setState(cur => ({
          imgSrc: [...cur.imgSrc, {
            type: fileType,
            file: reader.result,
            name: file[0].name,
          }],
        }));
      };
      // eslint-disable-next-line react/destructuring-assignment
      this.props.getUploadedDocuments([...this.props.documents, file[0]]);
    }
  }

  removeImage = (index) => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getUploadedDocuments(this.props.documents.filter((el, i) => i !== index));
    this.setState(cur => ({
      imgSrc: cur.imgSrc.filter((el, i) => i !== index),
    }));
  }

  render() {
    const {
      imgSrc,
    } = this.state;

    return (
      <MDBCol>
        <MDBRow className="no-margins">
          <MDBCol md="4">
            <div className="upload_deliverables">
              <input
                type="file"
                className="form-control uploadcheck font11"
                placeholder="Upload file"
                onChange={this.handleFileUpload}
              />
            </div>
          </MDBCol>
          <MDBCol className="flex-box" style={{ alignItems: 'end' }}>
            <div className="d-flex">
              <MDBInput
                className="float-right"
                label="Deliverables Required"
                type="checkbox"
                id="isNeedDeliverables"
                name="isNeedDeliverables"
              />
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow className="h-paddings-half">
          {
            imgSrc.map((data, i) => (
              FilePreviewElement({
                fileInfo: data,
                index: i,
                data,
                toggleModal: this.props.toggleModal,
                removeImage: this.removeImage,
              })
            ))
          }
        </MDBRow>
      </MDBCol>
    );
  }
}

export default UploadDocumentsToWorkOrder;
