import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { SubmissionError, submit } from 'redux-form';
import {
  MDBBtn,
  MDBCol,
  MDBIcon, MDBModal,
  MDBModalBody,
  MDBRow,
} from 'mdbreact';
import Select from 'react-select';
import { toggleModal } from '../../storage/actions/modalsActions';
import Notification from '../notification';
import CreateClientApprovalCodeReduxForm from '../layout/reduxForm/CreateClientApprovalCode';
import validate from '../../utils/reduxForm/validate/createCustomField';
import { createApprovalCode, createCustomField } from '../../storage/actions/workOrdersActions';
import getApprovalCodesList from '../../utils/api/get/getApprovalCodesList';
import { white as customSelectStyle } from '../../utils/customSelectStyle';

const CreateOrAddClientApprovalCodeToWorkOrder = ({
  modalState,
  toggleModal,
  submit,
}) => {
  const [appruvalCodes, setApprovalCodes] = useState([]);
  const [selectedApprovalCode, setSelectedApprovalCode] = useState();

  const createApprovalCodesOptionsForSelect = list => (
    list.map(approvalCode => ({
      value: approvalCode._id,
      label: approvalCode.name,
      required: approvalCode.required,
    }))
  );

  // eslint-disable-next-line consistent-return
  const fetchClientApprovalCodes = async () => {
    const newApprovalCodes = await getApprovalCodesList();
    if (!(modalState.type?.input.value?.length > 0)) {
      return setApprovalCodes(
        createApprovalCodesOptionsForSelect(newApprovalCodes),
      );
    }
    const selectedApprovalCodesIds = modalState.type?.input.value.map(item => (
      item._id || item.value
    ));
    setApprovalCodes(
      createApprovalCodesOptionsForSelect(
        newApprovalCodes.filter(customField => !selectedApprovalCodesIds.includes(
          customField._id,
        )),
      ),
    );
  };

  useEffect(() => {
    fetchClientApprovalCodes();
  }, []);

  const closeModal = () => {
    toggleModal(!modalState.isOpen, 'createOrAddClientApprovalCodeToWorkOrder');
  };

  const getApprovalCodeInfo = async (name, required, dispatch) => {
    if (selectedApprovalCode?.value) {
      return ({
        approvalCodeId: selectedApprovalCode.value,
        name: selectedApprovalCode.label,
      });
    }
    console.log(123321)
    const result = await dispatch(createApprovalCode(name, required));
    if (!result.success) throw new SubmissionError({ _error: result.message });
    return ({
      approvalCodeId: result?.payload?.newDocument?._id,
      name: result?.payload?.newDocument?.name,
    });
  };

  const onSubmit = async (values, dispatch, props) => {
    // const validationErrors = validate(values);
    // if (Object.keys(validationErrors).length) throw new SubmissionError(validationErrors);
    // if (!props.dirty) return Notification('warning', { message: 'None changes found!' });
    const {
      name,
      isRequired,
    } = values;
    const approvalCodeInfo = await getApprovalCodeInfo(name, isRequired.value, dispatch);
    addApprovalCodeToWorkOrder({
      approvalCodeId: approvalCodeInfo.approvalCodeId,
      name: approvalCodeInfo.name,
      required: isRequired.value,
    });
    return {};
  };

  // eslint-disable-next-line consistent-return
  const handlerSubmit = event => {
    event.preventDefault();
    submit('createClientApprovalCodeReduxForm');
  };

  const addApprovalCodeToWorkOrder = approvalCode => {
    // if (!(modalState.type?.input.value?.length > 0)) {
    //   modalState.type.input.onChange([approvalCode]);
    // } else {
      modalState.type.input.onChange([...modalState.type?.input.value, approvalCode]);
    // }
    closeModal();
  };

  return (
    <MDBModal
      className="applypop modal-width-500"
      isOpen={modalState.isOpen}
      toggle={closeModal}
      fullHeight
      position="right"
    >
      <MDBModalBody style={{ color: 'white' }}>
        <MDBRow className="h-paddings-half">
          <MDBCol style={{ textAlign: 'left' }}>
            <h5
              className="font-weight-bold"
              style={{ marginBottom: '15px' }}
            >
              Add Client Approval Code
            </h5>
          </MDBCol>
          <MDBCol md="2" style={{ textAlign: 'end' }}>
            <MDBIcon
              far
              icon="times-circle"
              onClick={closeModal}
              size="lg"
              className="blue-grey-text"
            />
            {console.log({selectedApprovalCode})}
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <Select
              isClearable
              isSearchable
              options={appruvalCodes}
              value={selectedApprovalCode}
              styles={customSelectStyle}
              onChange={setSelectedApprovalCode}
              placeholder="Select Custom Field"
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className="h-paddings-half">
          <MDBCol style={{ textAlign: 'left' }}>
            <h5
              className="font-weight-bold"
              style={{ marginBottom: '15px' }}
            >
              - OR -
            </h5>
          </MDBCol>
        </MDBRow>
        <MDBRow className="h-paddings-half">
          <MDBCol style={{ textAlign: 'left' }}>
            <h6
              className="font-weight-bold"
              style={{ marginBottom: '15px' }}
            >
              Create Client Approval Code
            </h6>
          </MDBCol>
        </MDBRow>
        <CreateClientApprovalCodeReduxForm
          enableReinitialize
          onSubmit={onSubmit}
          disabled={!!selectedApprovalCode?.value}
          initialValues={selectedApprovalCode?.value ? { name: selectedApprovalCode.label, isRequired: selectedApprovalCode.required } : {}}
        />
        <MDBRow>
          <MDBCol>
            <div style={{ textAlign: 'end' }}>
              <MDBBtn
                type="button"
                color="secondary"
                onClick={closeModal}
                className="clientclose"
              >
                Close
              </MDBBtn>
              <MDBBtn
                color="primary"
                className="clientsave"
                type="submit"
                onClick={handlerSubmit}
              >
                Save
              </MDBBtn>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBModalBody>
    </MDBModal>
  );
};

const mapDispatchToProps = {
  toggleModal,
  submit,
};

const mapStateToProps = state => ({
  modalState: state.modalState.createOrAddClientApprovalCodeToWorkOrder,
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrAddClientApprovalCodeToWorkOrder);
