import React from 'react';
import { connect } from 'react-redux';
import {
  MDBBtn,
  MDBCol,
  MDBIcon, MDBModal,
  MDBModalBody,
  MDBRow,
} from 'mdbreact';
import { submit } from 'redux-form';
import { toggleModal } from '../../storage/actions/modalsActions';
import CreateClientApprovalCodeReduxForm from '../layout/reduxForm/CreateClientApprovalCode';

const EditClientApprovalCodeModal = ({
  modalState,
  toggleModal,
  submit,
}) => {
  const closeModal = () => {
    toggleModal(!modalState.isOpen, 'editClientApprovalCodeModal');
  };

  const addCustomFieldToWorkOrder = approvalCode => {
    if (!(modalState.type?.input.value?.length > 0)) {
      modalState.type.input.onChange([approvalCode]);
    } else if (modalState.type?.index > 0 || modalState.type?.index === 0) {
      const newCustomFields = modalState.type?.input.value.map((item, index) => (
        index !== modalState.type?.index
          ? item
          : approvalCode
      ));
      modalState.type.input.onChange(newCustomFields);
    } else {
      modalState.type.input.onChange([...modalState.type?.input.value, approvalCode]);
    }

    closeModal();
  };

  const onSubmit = async (values, dispatch, props) => {
    const {
      name,
      isRequired,
      approvalCodeId
    } = values;

    addCustomFieldToWorkOrder({
      approvalCodeId,
      name,
      required: isRequired.value,
    });

    return {};
  };

  const handlerSubmit = async event => {
    event.preventDefault();
    submit('createClientApprovalCodeReduxForm');
  };

  return (
    <MDBModal
      className="applypop modal-width-500"
      isOpen={modalState.isOpen}
      toggle={closeModal}
      fullHeight
      position="right"
    >
      <MDBModalBody style={{ color: 'white' }}>
        <MDBRow className="h-paddings-half">
          <MDBCol style={{ textAlign: 'left' }}>
            <h6
              className="font-weight-bold"
              style={{ marginBottom: '15px' }}
            >
              Edit Client Approval Code
            </h6>
          </MDBCol>
          <MDBCol md="2" style={{ textAlign: 'end' }}>
            <MDBIcon
              far
              icon="times-circle"
              onClick={closeModal}
              size="lg"
              className="blue-grey-text"
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <CreateClientApprovalCodeReduxForm
              enableReinitialize
              onSubmit={onSubmit}
              disabled
              initialValues={modalState?.type?.field || {}}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <div style={{ textAlign: 'end' }}>
              <MDBBtn
                type="button"
                color="secondary"
                onClick={closeModal}
                className="clientclose"
              >
                Close
              </MDBBtn>
              <MDBBtn
                color="primary"
                className="clientsave"
                type="submit"
                onClick={handlerSubmit}
              >
                Save
              </MDBBtn>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBModalBody>
    </MDBModal>
  );
};

const mapDispatchToProps = {
  toggleModal,
  submit,
};

const mapStateToProps = state => ({
  modalState: state.modalState.editClientApprovalCodeModal,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditClientApprovalCodeModal);
