import React from 'react';
import {
  MDBCol, MDBIcon, MDBRow, MDBTable, MDBTableBody, MDBTableHead,
} from 'mdbreact';
import ControlsButton from '../ControlsButton';
import workOrderStatusControl from '../../../utils/workOrderStatusControl';
import { localCurrencySettings, variableTypeShorts } from '../../../utils/constans';
import EstimatedTimeOverageLabel from '../../layout/EstimatedTimeOverageLabel';
import formatAMPM from '../../../utils/formatAMPM';
import { getDateWithOffset } from '../../../utils/getDateWithOffset';
import ToolsElement from '../../layout/workOrderEditElements/selectWorkOrderWorkTypes/ToolsElement';
import getQtyByPayType from '../../../utils/getQtyByPayType';
import { useMemo } from 'react';
import { calculateFundsRequiredSum } from '../../../utils/calculateFundsRequiredSum';

//

const isLessThanHalfDay = (startDate) => ((new Date(startDate) - Date.now())) < 12 * 60 * 60 * 1000;

const WorkOrderCard = ({
  workOrder,
  activeTab,
  selectedWorkOrders,
  userType,
  setCurrentWorkOrder,
  fetchApplicantsForWorkOrder,
  toggleModal,
  messagesCount,
  selectWorkOrder,
  publishWorkOrder,
  acceptInvite,
  confirmWorkOrder,
  createWorkOrderTemplateByWorkOrder,
}) => {
  const workOrderControlByStatus = workOrderStatusControl(workOrder.status);
  const finalEstimateTotal = useMemo(() => {
    const basicQty = getQtyByPayType(
      workOrder.basicType,
      workOrder.approvedHoursQty || workOrder.quantity,
      workOrder.approvedDeviceQty || workOrder.maxQuantity,
      workOrder.quantity,
      workOrder.payBaseRateAsMinimum,
    );
    let approvedBasePotentialPay = (workOrder.bidAmountBase || workOrder.amount) * basicQty;
    if (parseFloat(workOrder.bidAmountVariable) || parseFloat(workOrder.variableAmount)) {
      const actualVariableQty = getQtyByPayType(
        workOrder.variableType,
        workOrder.approvedHoursQty || workOrder.quantity,
        workOrder.approvedDeviceQty || workOrder.maxQuantity,
        Infinity,
      );

      if (actualVariableQty > parseFloat(workOrder.variablePayAfter)) {
        const diffOfBaseTotalHrs = Math.min(
          parseFloat(actualVariableQty) - parseFloat(workOrder.variablePayAfter),
          parseFloat(workOrder.maxQuantity) - parseFloat(workOrder.variablePayAfter),
        );
        const variablePay = diffOfBaseTotalHrs * (workOrder.bidAmountVariable || workOrder.variableAmount);
        approvedBasePotentialPay += variablePay;
      }
    }
    return approvedBasePotentialPay;
  }, [workOrder])

  const totalEstPaySum = useMemo(() => {
    const sum = calculateFundsRequiredSum({
      amount: workOrder.bidAmountBase || workOrder.amount,
      basicType: workOrder.basicType,
      quantity: workOrder.quantity,
      variableAmount: workOrder.variableAmount,
      maxQuantity: workOrder.maxQuantity,
      variablePayAfter: workOrder.variablePayAfter
    }).toLocaleString('en-US', localCurrencySettings);

    const sumNum = Number(sum.slice(1));
    if (
      workOrder.payBaseRateAsMinimum && sumNum < workOrder.amount) {
      return workOrder.potentialPay
    }
    return sum;
  }, [workOrder])

  const basePaySum = useMemo(() => {
    let hrs = workOrder.approvedHoursQty || workOrder.quantity;
    let pay;
    if (workOrder.approvedHoursQty > workOrder.quantity) {
      hrs = workOrder.quantity
    }
    if (workOrder.basicType === 'Site') {
      hrs = 1
    }
    if(workOrder.basicType === 'Device') {
      hrs = (workOrder.approvedDeviceQty || workOrder.qtyDevices) < workOrder.quantity ? workOrder.approvedDeviceQty || workOrder.qtyDevices : workOrder.quantity
    }
    if (workOrder.approvedHoursQty < workOrder.quantity && !workOrder.payBaseRateAsMinimum) {
      pay = (workOrder.bidAmountBase || workOrder.amount) * hrs;
    } else if (workOrder.payBaseRateAsMinimum && workOrder.approvedHoursQty < workOrder.quantity) {
      pay = (workOrder.bidAmountBase || workOrder.amount) * workOrder.quantity;
    } else {
      pay = (workOrder.bidAmountBase || workOrder.amount) * hrs;
    }
    return pay;
  }, [workOrder])

  const basePay = useMemo(() => {
    let hrs = workOrder.approvedHoursQty || workOrder.quantity;
    if (workOrder.approvedHoursQty > workOrder.quantity) {
      hrs = workOrder.quantity
    }

    if (workOrder.basicType === 'Device') {
      hrs += ' device(s)'
    } else {
      hrs += ' hr(s)'
    }
    return `${workOrder.bidAmountBase || workOrder.amount}/${variableTypeShorts[workOrder.basicType]} for ${hrs} = ${basePaySum}`
  }, [workOrder]);

  const variablePaySum = useMemo(() => {

    return ((workOrder.bidAmountVariable || workOrder.variableAmount) * (workOrder.maxQuantity ? workOrder.maxQuantity - workOrder.variablePayAfter : 0)) || 0
  }, [workOrder])

  const varPay = useMemo(() => {
    if (workOrder.variableType === 'Hour') {
      return `
        ${workOrder.bidAmountVariable || workOrder.variableAmount}/hr after ${workOrder.variablePayAfter} for max ${workOrder.maxQuantity} hrs = ${variablePaySum}
      `
    }
    if(workOrder.variableType === 'Device') {
      return `
        ${workOrder.bidAmountVariable || workOrder.variableAmount}/dev after ${workOrder.variablePayAfter} for max ${workOrder.maxQuantity} devices = ${variablePaySum}
      `
    }
  }, [workOrder, variablePaySum])

  // getApprovedExpensesAmount = () => {
  //   const { selectedExpenses } = this.state;
  //   return selectedExpenses.reduce((acc, cur) => acc + cur.amount, 0);
  // }
  // this.setState({ finalEstimateTotal: approvedBasePotentialPay });

  return (
    <MDBTable className="contractor_table" borderless>
      <MDBTableHead />
      <MDBTableBody>
        <tr>
          <td>
            <MDBRow className="no-margins">
              <MDBCol md="3" style={{ paddingLeft: '0px' }}>
                <h6>
                  <b>{workOrder.title}</b>
                </h6>
              </MDBCol>
              <MDBCol md="8" style={{ paddingLeft: '5px' }}>
                <h6 className="font14">
                  {
                    userType === 'contractor'
                      ? (
                        <>
                          <b>Client:</b>
                          <span className="dashdiv">{workOrder.companyNCR.name}</span>
                        </>
                      )
                      : (
                        <>
                          <b>Created By:</b>
                          <span className="dashdiv">{workOrder.clientInfo?.name || workOrder.companyNCR.name}</span>
                        </>
                      )
                  }
                  <span>{`${workOrder.clientInfo?.ratings || 100}%`}</span>
                </h6>
              </MDBCol>
            </MDBRow>
            <MDBRow className="no-margins" style={{ flexWrap: 'nowrap' }}>
              <MDBCol style={{ paddingLeft: '0px' }}>
                <div className="font12" style={{ paddingBottom: '5px' }}>
                  <span style={{ fontWeight: 'bold' }}>WIN: </span>
                  {workOrder.WIN}
                </div>
                <div className="font12" style={{ paddingBottom: '5px' }}>
                  <span
                    style={{ fontWeight: 'bold' }}
                  >
                    Company WO ID:
                  </span>
                  {workOrder.customTagId}
                </div>
                {workOrder.status === "deleted" && <div className="font12 text-danger font-weight-bold" style={{ paddingBottom: '5px' }}>
                  Deleted WO
                </div>}
                {workOrder.status === "canceled" && <div className="font12 text-warning font-weight-bold" style={{ paddingBottom: '5px' }}>
                  Canceled WO
                </div>}
                <div className="font12">
                  {workOrder.onHold && (
                    <div className="wo-status-on-hold"> ON HOLD </div>
                  )}
                </div>
                {
                  userType !== 'contractor' && workOrder?.templateId
                    ? (
                      <div
                        title="Create using template"
                        className="blue-grey-text font-size-08 text-in-one-line"
                      >
                        Template Used
                      </div>
                    )
                    : <></>
                }
                {
                  userType !== 'contractor' && workOrder.status === 'assigned'
                    ? (
                      <div className="font12">
                        {
                          workOrder.confirmed
                            ? <div className="confirm"> CONFIRMED </div>
                            : (
                              <div className={`need-confirm ${isLessThanHalfDay(workOrder.startDate)
                                ? 'less-twelve'
                                : 'more-twelve'}`}
                              >
                                UNCONFIRMED
                              </div>
                            )
                        }
                      </div>
                    )
                    : null
                }
              </MDBCol>
              <MDBCol>
                {
                  userType === 'contractor'
                    ? (
                      <div
                        className="font12 flex-box flex-wrap"
                        style={{
                          paddingBottom: '5px',
                          minWidth: 300,
                        }}
                      >
                        {
                          workOrder.workTypes
                          && workOrder.workTypes instanceof Array
                          && workOrder.workTypes.map((tool) => (
                            <ToolsElement
                              key={tool.title}
                              label={tool.title}
                              className="minimal-style"
                            />
                          ))
                        }
                      </div>
                    )
                    : <></>
                }
                <div
                  className="font12 flex-box"
                  style={{ paddingBottom: '5px' }}
                >
                  <span style={{ fontWeight: 'bold' }}>Start: </span>
                  {new Date(workOrder.startDate).toLocaleDateString('en-US', { timeZone: 'UTC' })
                    === 'Invalid Date' ? (
                    ''
                  ) : (
                    <div>
                      <span style={{ paddingLeft: '5px' }}>
                        {
                          new Date(workOrder.startDate).toLocaleDateString(
                            'en-US', { timeZone: 'UTC' },
                          )
                        }
                      </span>
                      <span style={{ paddingLeft: '5px' }}>
                        {workOrder.startTime}
                      </span>
                    </div>
                  )}
                </div>
                <div
                  className="font12 flex-box"
                  style={{ paddingBottom: '5px' }}
                >
                  <span style={{ fontWeight: 'bold' }}>End: </span>
                  {new Date(workOrder.endDate).toLocaleDateString('en-US', { timeZone: 'UTC' })
                    === 'Invalid Date' ? (
                    ''
                  ) : (
                    <div>
                      <span style={{ paddingLeft: '5px' }}>
                        {
                          new Date(workOrder.endDate).toLocaleDateString(
                            'en-US', { timeZone: 'UTC' },
                          )
                        }
                      </span>
                      <span style={{ paddingLeft: '10px' }}>
                        {workOrder.endTime}
                      </span>
                    </div>
                  )}
                </div>
                {
                  workOrderControlByStatus.assigned
                    && !workOrder.checkInOutDetails.length
                    && (workOrder.contractorStatus?.workStatus === 'onWay')
                    ? (
                      <div style={{ color: '#ffff32' }}>
                        <MDBIcon icon="truck" flip="horizontal" style={{ marginRight: '0.5rem' }} />
                        <span>En Route</span>
                      </div>
                    )
                    : <></>
                }
                {
                  userType !== 'contractor' && !workOrderControlByStatus.beforeAssigned
                    && workOrder.checkInOutDetails.length
                    ? (
                      <div>
                        <div className="last-check-in-out-info-container">
                          <div>
                            <label>
                              Last Check In:
                              {' '}
                              {formatAMPM(
                                getDateWithOffset(
                                  workOrder
                                    .checkInOutDetails[workOrder.checkInOutDetails.length - 1]
                                    .check_in_date,
                                ),
                              )}
                            </label>
                          </div>
                          {
                            workOrder
                              .checkInOutDetails[workOrder.checkInOutDetails.length - 1]
                              .check_out_date
                              ? (
                                <div>
                                  <label>
                                    Checked Out:
                                    {' '}
                                    {formatAMPM(
                                      getDateWithOffset(
                                        workOrder
                                          .checkInOutDetails[
                                          workOrder.checkInOutDetails.length - 1
                                        ]
                                          .check_out_date,
                                      ),
                                    )}
                                  </label>
                                </div>
                              )
                              : <></>
                          }
                        </div>
                      </div>
                    )
                    : <></>
                }
              </MDBCol>
              <MDBCol style={{ marginRight: 0 }}>
                {
                  userType !== 'superAdmin'
                    ? (
                      <div className="font12" style={{ paddingBottom: '5px' }}>
                        {/* eslint-disable-next-line */}
                        <div
                          role="dialog"
                          style={{
                            cursor: 'pointer',
                            display: 'flex',
                          }}
                          onClick={
                            () => {
                              fetchApplicantsForWorkOrder(workOrder._id);
                              toggleModal(true, 'workOrderMessageSystemModal', true, { workOrder });
                            }
                          }
                        >
                          <MDBIcon icon="comments" />
                          <div style={{
                            color: '#65ee2f',
                            marginLeft: '0.25rem',
                          }}
                          >
                            {
                              messagesCount
                                ? (
                                  <div style={{ display: 'flex' }}>
                                    <div>
                                      {
                                        messagesCount.unReadMessages[workOrder._id]?.all === 1
                                          ? '1 message'
                                          : `${messagesCount.unReadMessages[workOrder._id]?.all || 0} messages`
                                      }
                                    </div>
                                    {messagesCount.unReadMessages[workOrder._id]?.unread
                                      ? (
                                        <div>
                                          <sup style={{
                                            color: 'white',
                                            background: 'red',
                                            padding: '0px 3px',
                                            marginLeft: '5px',
                                            borderRadius: '50px',
                                          }}
                                          >
                                            {messagesCount.unReadMessages[workOrder._id]?.unread}
                                          </sup>
                                        </div>
                                      )
                                      : null}
                                  </div>
                                )
                                : null
                            }
                          </div>
                        </div>
                      </div>
                    )
                    : <></>
                }
                <div className="font12" style={{ paddingBottom: '5px' }}>
                  {workOrder.city
                    ? ` ${workOrder.city}`
                    : ''}
                  {workOrder?.city
                    && workOrder?.state?.label
                    ? ','
                    : ''}
                  {workOrder?.state?.label && (
                    <span>{` ${workOrder.state.label}`}</span>
                  )}
                  {` ${workOrder.zipCode || ''}`}
                </div>
                <div className="font12">
                  {workOrder.distance
                    ? `${workOrder.distance.toFixed(2)} Miles`
                    : ''}
                </div>
              </MDBCol>
              {
                // eslint-disable-next-line no-nested-ternary
                userType !== 'contractor'
                  ? workOrder.contractor_id
                    ? (
                      <MDBCol>
                        <div
                          className="font12"
                          style={{ paddingBottom: '5px' }}
                        >
                          {
                            workOrder.contractorInfo
                              ? (
                                <>
                                  <span>Assigned to :</span>
                                  <br />
                                  <span>{workOrder.contractorInfo.name}</span>
                                  <br />
                                  <span>{workOrder.contractorInfo.phone}</span>
                                  <br />
                                  <span>{workOrder.contractorInfo.email}</span>
                                </>
                              )
                              : <span>Assigned</span>
                          }
                        </div>
                      </MDBCol>
                    )
                    : (
                      <MDBCol>
                        <div
                          className="font12"
                          style={{ paddingBottom: '5px' }}
                        >
                          <span>
                            Applicants:
                            {workOrder.applicant_count}
                          </span>
                        </div>
                        <div
                          className="font12"
                          style={{ paddingBottom: '5px' }}
                        >
                          <span>
                            Routed To:
                            {' '}
                            {(workOrder.routed_count) && workOrder.routed_count}
                          </span>
                        </div>
                      </MDBCol>
                    )
                  : null
              }
            </MDBRow>
          </td>
          {
            !workOrderControlByStatus.workUncompleted
              && !workOrderControlByStatus.canceled
              ? (
                <td className="position-relative" style={{ width: 220 }}>
                  <div
                    className="flex-center flex-column text-center"
                  >
                    <div style={{ fontSize: '15px' }}>
                      {workOrderControlByStatus.approved ? 'Final Pay' : 'Estimated Final Pay'}
                    </div>
                    <div className="dollerdiv">
                      <h2
                        style={{
                          color: '#28a745',
                          marginBottom: '-6px',
                          fontWeight: 'bold',
                        }}
                      >
                        {
                          parseFloat(
                            workOrderControlByStatus.completed || userType === 'contractor'
                              ? (
                                parseFloat(workOrder.finalPayAdjustment || 0)
                                + parseFloat(finalEstimateTotal)
                                // + this.getApprovedExpensesAmount()
                              )
                              : (workOrder.finalPayGoFromWallet || workOrder.finalPay),
                          )
                            .toLocaleString('en-US', localCurrencySettings)
                        }
                      </h2>
                    </div>
                    <div style={{ fontSize: '15px' }}>
                      {
                        `For ${getQtyByPayType(
                          workOrder.basicType,
                          workOrder.approvedHoursQty,
                          workOrder.approvedDeviceQty,
                          Infinity,
                        )
                        } ${workOrder.basicType}${workOrder.basicType !== 'Site' ? '(s)' : ''}`
                      }
                      <br />
                      <div style={{
                        fontSize: '10px',
                        textAlign: 'left',
                      }}
                      >
                        Base: {basePay}
                      </div>
                      {workOrder.bidAmountVariable ?
                        <div style={{
                          fontSize: '10px',
                          textAlign: 'left',
                        }}
                        >
                          Var: {varPay}
                        </div> : <></>
                      }
                      <div style={{
                        fontSize: '10px',
                        textAlign: 'left',
                      }}
                      >
                        Total est value = {totalEstPaySum}
                      </div>
                    </div>
                    {
                      workOrderControlByStatus.completed && userType !== 'contractor'
                        && workOrder.basicType === 'Hour'
                        && (
                          workOrder.approvedHoursQty > (
                            workOrder.quantity
                            + (workOrder.variableType === 'Hour' ? (workOrder.maxQuantity - workOrder.variablePayAfter) : 0)
                          )
                        )
                        ? (
                          <EstimatedTimeOverageLabel
                            text="Over Est. Time!"
                          />
                        )
                        : <></>
                    }
                  </div>
                </td>
              )
              : (
                <td>
                  <div className="dollerdiv">
                    <h2
                      style={{
                        color: '#28a745',
                        marginBottom: '-6px',
                        fontWeight: 'bold',
                      }}
                    >
                      {
                        (
                          (
                            workOrderControlByStatus.beforeAssigned
                              && !workOrderControlByStatus.canceled
                              ? workOrder.amount
                              : (workOrder.bidAmountBase || workOrder.amount)
                          )
                          * ((workOrder.payBaseRateAsMinimum && workOrder.basicType !== 'Site') ? workOrder.quantity : 1)
                        ).toLocaleString('en-US', localCurrencySettings)
                      }
                    </h2>
                  </div>

                  <div style={{
                    fontSize: '15px',
                    textAlign: 'right',
                  }}
                  >
                    {
                      // eslint-disable-next-line no-nested-ternary
                      workOrder.payBaseRateAsMinimum
                        ? 'MIN'
                        : workOrder.basicType && workOrder.basicType !== ''
                          ? `Per ${workOrder.basicType === 'Hours'
                            ? 'Hour'
                            : workOrder.basicType}`
                          : ''
                    }
                  </div>
                  <div style={{
                    fontSize: '10px',
                    textAlign: 'left',
                  }}
                  >

                    Base: {basePay}
                  </div>
                  {(workOrder.bidAmountVariable || workOrder.variablePayAfter) && workOrder.maxQuantity ?
                    <div style={{
                      fontSize: '10px',
                      textAlign: 'left',
                    }}
                    >
                      Var: {varPay}
                    </div> : <></>
                  }
                  <div style={{
                    fontSize: '10px',
                    textAlign: 'left',
                  }}
                  >
                    Total est value = {totalEstPaySum}
                  </div>
                </td>
              )
          }
          <ControlsButton
            workOrder={workOrder}
            activeTab={activeTab}
            selectedWorkOrders={selectedWorkOrders}
            userType={userType}
            fetchApplicantsForWorkOrder={fetchApplicantsForWorkOrder}
            toggleModal={toggleModal}
            selectWorkOrder={selectWorkOrder}
            publishWorkOrder={publishWorkOrder}
            setCurrentWorkOrder={setCurrentWorkOrder}
            acceptInvite={acceptInvite}
            confirmWorkOrder={confirmWorkOrder}
            createWorkOrderTemplateByWorkOrder={createWorkOrderTemplateByWorkOrder}
          />
        </tr>
      </MDBTableBody>
    </MDBTable>
  );
};

export default WorkOrderCard;
